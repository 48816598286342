import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import cannmed2019 from '../assets/images/cannmed19.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic06 from '../assets/images/pic06.jpg'
import ulex from '../assets/images/ulex.png'
import agtech from '../assets/images/agtech.jpg'
import jsj from '../assets/images/journal-special-juris.png'
import training from '../assets/images/training.jpg'
import cannabis from '../assets/images/cannabis.jpg'


class HomeIndex extends React.Component {
    render() {

        return (
            <Layout>
                <Helmet
                    title="CRYPTOTECH - Blockchain Networks and Business Development"
                    meta={[
                        { name: 'description', content: 'Community Technology; Blockchain Consultants; Peer-to-peer Protocols; Decentralized Consensus; ' },
                        { name: 'keywords', content: 'social scalability, community technology, blockchain, cryptocurrency, peer-to-peer, P2P, consensus, bysantine' },
                    ]}
                >
                </Helmet>

                <Banner />

                <div id="main">
                    <section id="one" className="tiles">
                        <article style={{backgroundImage: `url(${jsj})`}}>
                            <header className="major">
                                <p>&nbsp;</p><p>&nbsp;</p>
                                <p>Our <b>Lexinomicon</b> software development game is referenced in the very first issue of this peer-reviewed academic journal.</p>
                            </header>
                            <a href="http://ojs.instituteforcompgov.org/index.php/jsj/issue/view/1/Special%20Issue%20on%20Non-territorial%20Governance" className="link primary">&nbsp;</a>
                        </article>
                        <article style={{backgroundImage: `url(${cannmed2019})`}}>
                            <header className="major">
                                <h3>CannMed2019 Cultivation Panel</h3>
                                <p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>
                                <p>Breeders Rights, Cannabis IP, Blockchains: <br></br>Their role in Appellations and Provenance.</p>
                            </header>
                            <a href="https://www.youtube.com/watch?v=DYW5O1lnWV4" className="link primary" target="YouTube">&nbsp;</a>
                        </article>
                        <article style={{backgroundImage: `url(${pic03})`}}>
                            <header className="major">
                                <h3>Techniques & Skills</h3>
                                <p>P2P privacy, crypto security, and decentralized collaboration.</p>
                            </header>
                            <Link to="/priorworks" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${cannabis})`}}>
                            <header className="major">
                                <h3>Kannapedia.net</h3>
                                <p>Proof of existence registration of cannabis genetic profile on the Bitcoin blockchain.</p>
                            </header>
                            <Link to="/priorworks#kannapedia" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${agtech})`}}>
                            <header className="major">
                                <h3>Ripe.io</h3>
                                <p>The Blockchain of Food - Reimagining the food supply chain. Patent #US20180285810A1.</p>
                            </header>
                            <Link to="/priorworks#ripe" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Tesseract Crypto</h3>
                                <p>Securities on the Blockchain - Fork of bitcoin currently in Testnet.</p>
                            </header>
                            <a href="https://cryptotechguru.github.io/Cryptonomicon/Projects/Tesseract.html" className="link primary">&nbsp;</a>
                        </article>
                        <article style={{backgroundImage: `url(${training})`}}>
                            <header className="major">
                                <h3>Conferences and Education</h3>
                                <p>We present at global events and work with international institutions to promote the use of P2P consensus protocols to find new ways to solve old problems in a digital world.</p>
                            </header>
                            <Link to="/education" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${ulex})`}}>
                            <header className="major">
                                <h3>Lexinomicon</h3>
                                <p>Nomicon game space for the Ulex community</p>
                            </header>
                            <a href="https://ulex-opensource.github.io/Lexinomicon/" className="link primary">&nbsp;</a>
                        </article>
                    </section>
                </div>

            </Layout>
        )
    }
}

export default HomeIndex
