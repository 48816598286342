import React from 'react'

const Banner = (props) => (
    <section id="banner" className="major">
        <div className="inner">
            <header className="major">
                <h1>Technology Solutions For <br />Decentralized Communities.</h1>
            </header>
            <div className="content">
                <p>Community technology designed with passion <br />
                and powered by crypto-consensus collaboration protocols.</p>
                <ul className="actions">
                    <li><a href="#contact" className="button next scrolly">Contact Us</a></li>
                </ul><ul className="actions">
                    <li><a href="https://github.com/cryptotechguru/Projects/wiki" target="other" className="button next scrolly">Visit our Projects on Github</a></li>
                </ul>
            </div>
        </div>
    </section>
)

export default Banner
